<template lang="pug">
div(v-if='Nosotros')
  //-Pc
  .d-none.d-md-block
    v-row
      v-img(:src='Nosotros.imagen' max-width='400px' ).mr-8
      v-col(style='margin-top: 7%')
        v-row.fila-informacion
          v-container
            v-row
              v-img(:src='Nosotros.imagen_titulo' max-width='300px' width='300px' min-width='300px')
            v-row
              .fila(style='width: 90%')
                p(v-html='Nosotros.texto')
            v-row
              v-btn(v-for='{texto, enlace}, i in botones' :key='i' :posicion='i' rounded @click='abrir_enlace(enlace)').boton.celeste_secundario.elevation-0
                span.white--text {{texto}}
  //-Móvil
  .d-md-none
    v-row(style='margin-top: 10%; margin-bottom: 25px')
      v-img(:src='Nosotros.imagen' max-width='50%')
      .titulo-movil
        v-img(:src='Nosotros.imagen_titulo' max-width='150px' width='293px' min-width='150px' )
    v-row.justify-center
      .fila-movil(style='width: 80%; text-align: center')
        p(v-html='Nosotros.texto')
    v-row(v-for='{texto, enlace}, i in botones' :key='`m${i}`' @click='abrir_enlace(enlace)').justify-center.ma-5
      v-btn(:posicion='i' rounded x-large).celeste_secundario.elevation-0
        span.white--text {{texto}}

</template>
<script>
export default {
  data: ()=>({
    botones: [
      {texto: 'J|Ver perfil', enlace: 'https://jannaazabache.com/'}, 
    ],
  }),
  methods: {
    abrir_enlace(enlace){
      window.open(enlace, '_blank')
    }
  },
}
</script>
<style lang="sass" scoped>
.boton
  &[posicion='0']
    margin-right: 50px
.titulo-movil
  margin-left: 5%
  margin-top: 18%
  position: absolute
  left: 50%
</style>